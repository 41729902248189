import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebaseConfig';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const user = auth.currentUser;

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/login');
    });
  };

  return (
    <nav className="bg-blue-500 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-white font-bold text-xl">
          LinkPortal
        </Link>
        <div>
          {user ? (
            <>
              <button
                onClick={handleLogout}
                className="text-white ml-4"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="text-white ml-4">
                Login
              </Link>
              <Link to="/signup" className="text-white ml-4">
                Sign Up
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;