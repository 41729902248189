// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyAbn3qPhwKAJNNLNZZT6r4T31akdp-v4sI",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "linkportal-a4580.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "linkportal-a4580",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "linkportal-a4580.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "981671674265",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:981671674265:web:0911284949e253eed55308",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-5TMBT78NDP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
