import { getAuth, signOut } from 'firebase/auth';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../utils/firebaseConfig';

interface Link {
  id: string;
  name: string;
  url: string;
  description: string;
  order: number;
  active: boolean;
  userId: string;
  createdAt: Date;
  clicks: number;
  icon: string;
}

interface Header {
  id: string;
  text: string;
  userId: string;
}

const Dashboard: React.FC = () => {
  const [links, setLinks] = useState<Link[]>([]);
  const [headers, setHeaders] = useState<Header[]>([]);
  const [user, setUser] = useState<any>(null);
  const [username, setUsername] = useState<string>('');
  const [editUsername, setEditUsername] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#40E0D0');
  const auth = getAuth();
  const navigate = useNavigate();
  const updateTimersRef = useRef<{ [key: string]: NodeJS.Timeout }>({});

  const fetchLinks = useCallback(async (userId: string) => {
    const q = query(collection(db, 'links'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const fetchedLinks: Link[] = [];
    querySnapshot.forEach((doc) => {
      fetchedLinks.push({ id: doc.id, ...doc.data() } as Link);
    });
    setLinks(fetchedLinks.sort((a, b) => a.order - b.order));
  }, []);

  const fetchHeaders = useCallback(async (userId: string) => {
    const q = query(collection(db, 'headers'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const fetchedHeaders: Header[] = [];
    querySnapshot.forEach((doc) => {
      fetchedHeaders.push({ id: doc.id, ...doc.data() } as Header);
    });
    setHeaders(fetchedHeaders);
  }, []);

  const fetchUserData = useCallback(async (userId: string) => {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      setUsername(userData?.username || '');
    }
  }, []);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);
      fetchUserData(currentUser.uid);
      fetchLinks(currentUser.uid);
      fetchHeaders(currentUser.uid);
    } else {
      navigate('/login');
    }
  }, [auth.currentUser, fetchLinks, fetchHeaders, fetchUserData, navigate]);

  const addLink = async () => {
    if (user) {
      const newLink: Omit<Link, 'id'> = {
        name: 'New Link',
        url: '',
        description: '',
        order: links.length,
        active: true,
        userId: user.uid,
        createdAt: new Date(),
        clicks: 0,
        icon: ''
      };
      const docRef = await addDoc(collection(db, 'links'), newLink);
      setLinks(prevLinks => [...prevLinks, { ...newLink, id: docRef.id }]);
    }
  };

  const updateLink = useCallback((linkId: string, updatedData: Partial<Link>) => {
    setLinks(prevLinks => 
      prevLinks.map(link => 
        link.id === linkId ? { ...link, ...updatedData } : link
      )
    );
    
    if (updateTimersRef.current[linkId]) {
      clearTimeout(updateTimersRef.current[linkId]);
    }
    
    updateTimersRef.current[linkId] = setTimeout(() => {
      updateDoc(doc(db, 'links', linkId), updatedData)
        .catch(error => console.error("Error updating document: ", error));
      
      delete updateTimersRef.current[linkId];
    }, 1000);
  }, []);

  const deleteLink = async (linkId: string) => {
    await deleteDoc(doc(db, 'links', linkId));
    setLinks(prevLinks => prevLinks.filter(link => link.id !== linkId));
  };

  const addHeader = async () => {
    if (user) {
      const newHeader: Omit<Header, 'id'> = {
        text: 'New Header',
        userId: user.uid
      };
      const docRef = await addDoc(collection(db, 'headers'), newHeader);
      setHeaders(prevHeaders => [...prevHeaders, { ...newHeader, id: docRef.id }]);
    }
  };

  const updateHeader = useCallback((headerId: string, text: string) => {
    setHeaders(prevHeaders => 
      prevHeaders.map(header => 
        header.id === headerId ? { ...header, text } : header
      )
    );

    if (updateTimersRef.current[headerId]) {
      clearTimeout(updateTimersRef.current[headerId]);
    }

    updateTimersRef.current[headerId] = setTimeout(() => {
      updateDoc(doc(db, 'headers', headerId), { text })
        .catch(error => console.error("Error updating header: ", error));
      
      delete updateTimersRef.current[headerId];
    }, 1000);
  }, []);

  const deleteHeader = async (headerId: string) => {
    await deleteDoc(doc(db, 'headers', headerId));
    setHeaders(prevHeaders => prevHeaders.filter(header => header.id !== headerId));
  };

  const updateUsername = async (newUsername: string) => {
    if (user) {
      try {
        await updateDoc(doc(db, 'users', user.uid), { username: newUsername });
        setUsername(newUsername);
        setEditUsername(false);
        alert('Username updated successfully!');
      } catch (error) {
        console.error('Error updating username: ', error);
        alert('Error updating username.');
      }
    }
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error("Error signing out: ", error);
    });
  };

  const copyLinkPortalURL = () => {
    const url = `${window.location.origin}/${username}`;
    navigator.clipboard.writeText(url).then(() => {
      alert('LinkPortal URL copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  useEffect(() => {
    const currentTimers = updateTimersRef.current;
    return () => {
      Object.values(currentTimers).forEach(clearTimeout);
    };
  }, []);

  return (
    <div className="dashboard-container">
      <aside className="sidebar">
        <div className="logo">LinkPortal</div>
        <nav>
          <ul>
            <li className="active">Links</li>
            <li>Shop</li>
            <li>Appearance</li>
            <li>Analytics</li>
            <li>Settings</li>
          </ul>
        </nav>
        <div className="user-info">
          <div className="avatar">{user?.displayName?.[0] || 'U'}</div>
          
          {editUsername ? (
            <div className="username-edit">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="username-input"
              />
              <button onClick={() => updateUsername(username)} className="save-button">Save</button>
              <button onClick={() => setEditUsername(false)} className="cancel-button">Cancel</button>
            </div>
          ) : (
            <div>
              <span>@{username || 'User'}</span>
              <button onClick={() => setEditUsername(true)} className="edit-button">Edit</button>
            </div>
          )}

          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      </aside>
      
      <main className="main-content">
        <header>
          <div className="linkportal-url">
            🔥 Your LinkPortal is live: <a href={`/${username}`}>{window.location.origin}/{username}</a>
            <button className="copy-button" onClick={copyLinkPortalURL}>Copy your LinkPortal URL</button>
          </div>
        </header>

        <section className="username-section">
          <label htmlFor="username" className="block text-sm font-medium text-gray-700">Update Username</label>
          <input
            id="username"
            type="text"
            className="w-full p-2 border border-gray-300 rounded mt-1"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button onClick={() => updateUsername(username)} className="bg-blue-500 text-white p-2 rounded mt-2">Update Username</button>
        </section>

        <section className="add-link">
          <button onClick={addLink} className="add-link-button">+ Add link</button>
        </section>

        <section className="link-options">
          <button className="add-header-button" onClick={addHeader}>Add header</button>
          <button className="view-archive-button">View archive &gt;</button>
        </section>

        <section className="color-picker">
          <label htmlFor="background-color">Background Color:</label>
          <input
            type="color"
            id="background-color"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.target.value)}
          />
        </section>

        <section className="links-list">
          {headers.map((header) => (
            <div key={header.id} className="header-item">
              <input
                type="text"
                value={header.text}
                onChange={(e) => updateHeader(header.id, e.target.value)}
                className="header-input"
              />
              <button onClick={() => deleteHeader(header.id)} className="delete-button">🗑️</button>
            </div>
          ))}
          {links.length === 0 && headers.length === 0 ? (
            <div className="empty-state">
              <div className="empty-icon">📎</div>
              <p>Show the world who you are.</p>
              <p>Add a link or header to get started.</p>
            </div>
          ) : (
            links.map((link) => (
              <div key={link.id} className="link-item">
                <div className="link-drag-handle">⋮⋮</div>
                <div className="link-content">
                  <input
                    type="text"
                    value={link.name}
                    onChange={(e) => updateLink(link.id, { name: e.target.value })}
                    className="link-title-input"
                    placeholder="Enter link title"
                  />
                  <input
                    type="url"
                    value={link.url}
                    onChange={(e) => updateLink(link.id, { url: e.target.value })}
                    placeholder="Enter URL"
                    className="link-url-input"
                  />
                </div>
                <div className="link-actions">
                  <button 
                    className={`toggle-button ${link.active ? 'active' : ''}`}
                    onClick={() => updateLink(link.id, { active: !link.active })}
                  ></button>
                  <button className="action-button">Share</button>
                  <button className="action-button">Analytics</button>
                  <button className="delete-button" onClick={() => deleteLink(link.id)}>🗑️</button>
                </div>
              </div>
            ))
          )}
        </section>
      </main>

      <aside className="preview" style={{ backgroundColor }}>
        <div className="phone-mockup">
          <div className="avatar">{user?.displayName?.[0] || 'U'}</div>
          <h2>@{username}</h2>
          <p>{links.length} links</p>
          {headers.map((header) => (
            <div key={header.id} className="preview-header">{header.text}</div>
          ))}
          {links.map((link) => (
            <a key={link.id} href={link.url} target="_blank" rel="noopener noreferrer" className="preview-link">{link.name}</a>
          ))}
          <div className="linkportal-logo">LinkPortal*</div>
          <button className="hide-logo-button">Hide LinkPortal logo</button>
        </div>
      </aside>
    </div>
  );
};

export default Dashboard;