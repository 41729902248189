import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTiktok, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import { Link } from '../types/linkTypes';
import { db } from '../utils/firebaseConfig';

interface UserProfile {
  username: string;
  displayName: string;
  photoURL: string;
  backgroundColor: string;
}

const socialIcons: { [key: string]: React.ComponentType } = {
  instagram: FaInstagram,
  youtube: FaYoutube,
  whatsapp: FaWhatsapp,
  x: FaXTwitter,
  facebook: FaFacebookF,
  linkedin: FaLinkedinIn,
  tiktok: FaTiktok
};

const PublicProfile: React.FC = () => {
  const [links, setLinks] = useState<Link[]>([]);
  const [user, setUser] = useState<UserProfile | null>(null);
  const { username } = useParams<{ username: string }>();

  useEffect(() => {
    const fetchPublicProfile = async () => {
      try {
        // Fetch user data using `username`
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('username', '==', username)); // البحث باستخدام `username`
        const userSnapshot = await getDocs(q);

        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data() as UserProfile;
          setUser(userData);

          // Fetch user's links using userId
          const linksRef = collection(db, 'links');
          const linksQuery = query(linksRef, where('userId', '==', userSnapshot.docs[0].id), where('active', '==', true));
          const linksSnapshot = await getDocs(linksQuery);

          const fetchedLinks: Link[] = [];
          linksSnapshot.forEach((doc) => {
            fetchedLinks.push({ id: doc.id, ...doc.data() } as Link);
          });
          setLinks(fetchedLinks.sort((a, b) => (a.order || 0) - (b.order || 0)));
        }
      } catch (err) {
        console.error('Error fetching public profile:', err);
      }
    };

    if (username) {
      fetchPublicProfile();
    }
  }, [username]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ backgroundColor: user.backgroundColor || '#ffffff', minHeight: '100vh', padding: '20px' }}>
      <div className="profile-container">
        <img src={user.photoURL} alt={user.displayName} className="profile-picture" />
        <h1>{user.displayName}</h1>
        <div className="links-container">
          {links.map((link) => {
            const Icon = socialIcons[link.icon as keyof typeof socialIcons];
            return (
              <a key={link.id} href={link.url} target="_blank" rel="noopener noreferrer" className="link-item">
                <Icon />
                <span>{link.name}</span>
                {link.description && <p className="link-description">{link.description}</p>}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PublicProfile;
