// File: /Users/amadkata/linkportal/src/App.tsx

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import PublicProfile from './components/PublicProfile';
import LoginForm from './forms/LoginForm';
import SignupForm from './forms/SignupForm';
import './styles/Dashboard.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="container mx-auto my-8">
          <Routes>
            <Route path="/signup" element={<SignupForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/:username" element={<PublicProfile />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;